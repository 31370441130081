<template>
  <div class="container">
    <top-decorations version="2"></top-decorations>
    <div class="single-event-wrapper" v-if="newsData">
      <div class="event-type">
        <div class="bookmark-container">
          <div class="bookmark">
            EVENT
          </div>
          <div class="bookmark secondary">
            {{newsData.attributes.field_event_type}}
          </div>
        </div>
      </div>
      <h1 class="page-title">{{ newsData.attributes.title }}</h1>
      <div class="event-date">
        <img src="@/assets/icon-calendar.svg" alt="calendar">
        <div class="event-date-block">
          <span class="date-label">FROM</span>
          <div class="date-value">{{formatDate(newsData.attributes.field_news_date)}}
            <span class="time-value">{{formatTime(newsData.attributes.field_news_date)}}</span>
          </div>
        </div>
        <div class="event-date-block">
          <span class="date-label">TO</span>
          <div class="date-value">{{formatDate(newsData.attributes.field_end_date)}}
            <span class="time-value">{{formatTime(newsData.attributes.field_end_date)}}</span>
          </div>
        </div>
      </div>
      <div
        v-if="newsData.attributes.field_shared_description"
        v-html="newsData.attributes.field_shared_description.value"
      ></div>
      <div
        v-for="(attachment, index) of attachments"
        :key="index"
        class="btn-download"
      >
      <a target="_blank" :href="`${endpoint}${attachment.data.attributes.uri.url}`">
      <img src="@/assets/icon-download.svg" alt="">
      {{attachment.data.attributes.filename}}
      </a>
      </div>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
<script>
import TopDecorations from '@/components/TopDecorations.vue'
export default {
  components:{
    TopDecorations
  },
  data() {
    return {
      endpoint: process.env.VUE_APP_ENDPOINT,
      newsData: null,
      attachments: []
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getAttachments() {
      if (
        this.newsData.relationships.field_shared_attachment &&
        this.newsData.relationships.field_shared_attachment.data
      ) {
        this.newsData.relationships.field_shared_attachment.data.forEach(
          (attachment) => {
            fetch(
              `${process.env.VUE_APP_ENDPOINT}/jsonapi/file/file/${attachment.id}`
            )
              .then((r) => r.json())
              .then((json) => {
                this.attachments.push(json);
              });
          }
        );
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en", {
        weekday:"long",
        day: "numeric",
        month: "long",
        year: "numeric"
      });
    },
    formatTime(dateString) {
      return new Date(dateString).toLocaleTimeString("it",{
        'hour':"numeric",
        'minute':"numeric"
      });
    },
    fetchEvent() {
      fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/event/${this.id}`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.newsData = json.data;
          this.getAttachments();
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    },
  },
  mounted() {
    this.fetchEvent();
  },
};
</script>
<style lang="scss"  >
.bookmark.secondary{
  color: #acacac;
  font-weight: 700;
  text-transform: capitalize !important;
}
.page-title{
  margin-bottom: 80px;
  margin-top: 80px;
  color: #011B4A;
}
.single-event-wrapper{
  padding-bottom: 120px;
}
.event-date{
  display: flex;
  line-height: 1;
  margin-bottom: 30px;
  font-size: 18px;
  flex-wrap: wrap;
  align-items: flex-end;
  img{
    display: none;
  }
}

.date-label{
  display: inline-block;
  padding-bottom: 5px;
  color: #1DC07E;
  font-size: 12px; 
}
.time-value{
  margin-left: 8px;
  padding-left: 10px;
  display: inline-block;
  border-left: 2px solid #1DC07E;
}
@media (min-width:992px) {
  .event-date-block{
    padding: 0 20px;
  }
  .event-date {
    img{
      display: block;
    }
  }
}
</style>